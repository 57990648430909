/// NOTE: would be nice to replace all 100vh with 100dvh

//Colors
$white: #ffffff;
$black: rgba(0, 0, 0, 0.88);
$grey: #666666;
$darkgrey: #444444;
$mediumgrey: #aaaaaa;
$lightgrey: #eeeeee;
$dark: #313131;
$orange: #f6a623;
$blue: #2399e5;
$green: #1d9650;
$babyblue: #eeeeff;
$darkblue: #34495e;
$base-color: #ff0000;
$inputText: #000000;
$borderColor: #000000;
$red: #b9000d;
$gelb: #e6db7c;
$rosa: #e67ca6;

//Breakpoints
$bigscreen: 1800px;
$desktop: 1200px;
$tablet: 960px;
$mobile: 600px;
$low: 450px; // MOBILE_BREAKPOINT
$tiny: 320px;

//Sizes
$loginMaxWidth: 420px;
$loginMaxWidthMobile: 340px;
$loginMaxWidthMobileLow: 280px;

//Typography
//Fonts
$main-font: 'Manrope', sans-serif;
$header-font: 'Montserrat', sans-serif;
$card-title-font: 'Montserrat', sans-serif;
$visitedlink: $mediumgrey;
$link: $blue;
$big-font: 3em;

//Typo Base
$main-size: 10pt;
$heading1: 3em;
$heading2: 1.8em;
$heading3: 1.3em;
$heading4: 1.1em;
$text-size: 1em;
$button-font-size: 11pt;

//Typo Specific
$title-size: $heading1;
$background-color: $white;
$title-color: $black;
$text-color: $black;
$menubar: $background-color;

//Item Specific
$header-height: 64px; //56px;
$header-height-mobile: 50px;

//Social Media colors
$facebook: #3b5999;
$twitter: #55acee;
$whatsapp: #25d366;
$google: #dd4b39;
$linkedin: #0077b5;

// Menu
$menuWidth: 250px;
$menuWidthMin: 160px;
$menuWidthMax: 365px;
$menuMobileWidthMin: 50px;
$menuMobileWidthMax: 100%;
// $menu-transition: 0.3s ease;
$menu-transition: 0.35s cubic-bezier(0.25, 0.8, 0.25, 1);

// Editor Specific
$editor-padding: 5px;

// Colors Gobal
$highlightColor: $gelb;
$warning: $gelb;
$error: $red;
$success: $green;

$fade-out: 0.4;

/*

  NEW COLOR DEFINITIONS

*/

/*
 DARK THEME FOR APP
*/
$bg-color: #212121;
$on-bg-color: #fff;

$primary-color: #e6db7c;
$on-primary-color: #111;

$secondary-color: #37e1aa;
$on-secondary-color: #ffffff;

$error-color: #ffd2d2;
$on-error-color: #d8000c;

// '--error-color': '#FFD2D2',
// '--on-error-color': '#D8000C',

$warning-color: #f6a623;
$on-warning-color: #111;

$update-color: #20b351;
$on-update-color: #111;

$info-color: #bef;
$on-info-color: #059;

$disabled-color: #565c5d;
$on-disabled-color: #efedf1;

$surface-color: #444;
$on-surface-color: #eee;

$popup-color: rgba(255, 255, 255, 0.97);
$on-popup-color: #111;

/*
 LIGHT THEME FOR APP
*/
// $bg-color: #EFEDF1;
// $on-bg-color: #68676A;

// $primary-color: #e6db7c;
// $on-primary-color: #111;

// $secondary-color: #37E1AA;
// $on-secondary-color: #EFEDF1;

// $error-color:#C12937;
// $on-error-color: #fff;

// $warning-color: #f6a623;
// $on-warning-color: #111;

// $info-color: #2EC2FF;
// $on-info-color: #111;

// $disabled-color: #565C5D;
// $on-disabled-color: #EFEDF1;

// $surface-color: #eee;
// $on-surface-color: #444;

// Card
// $card-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$card-shadow:
  0 1px 3px rgba(0, 0, 0, 0.04),
  0 1px 2px rgba(0, 0, 0, 0.1);
// $card-hover-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
$card-hover-shadow:
  0 3px 6px rgba(0, 0, 0, 0.16),
  0 3px 6px rgba(0, 0, 0, 0.23);
$card-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

$menu-shadow:
  0 3px 16px 0 rgba(0, 0, 0, 0.2),
  0 1px 3px 0 rgba(0, 0, 0, 0.14),
  0 3px 2px -1px rgba(0, 0, 0, 0.12);

$bg-gradient: linear-gradient(-45deg, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 1) 100%);

// HOT COLORS

// Data Types Colors
$type-text-color: #ff7f00;
$type-number-color: #d420d4;
$type-date-color: #0082e4;
$type-geo-color: #007f00;
$type-coord-color: #2eb9b9;

// Cell Feedback Colors
$cell-warning: #ffb74d;
$cell-error: #ef5350;

// Cell value & label colors
$cell-source-color: #8ad9d8;
$cell-target-color: #bfb07e;
$cell-label-color: #f7ae42;
$cell-value-color: #c37ecf;
$cell-group-color: #97db70;
$cell-longitude-color: #35cf9c;
$cell-latitude-color: #4b71da;
