/* Manrope */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/assets/fonts/google/manrope-v15-latin-300.woff2') format('woff2'),
    url('/assets/fonts/google/manrope-v15-latin-300.woff') format('woff'),
    url('/assets/fonts/google/manrope-v15-latin-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/assets/fonts/google/manrope-v15-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/google/manrope-v15-latin-regular.woff') format('woff'),
    url('/assets/fonts/google/manrope-v15-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('/assets/fonts/google/manrope-v15-latin-500.woff2') format('woff2'),
    url('/assets/fonts/google/manrope-v15-latin-500.woff') format('woff'),
    url('/assets/fonts/google/manrope-v15-latin-500.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/assets/fonts/google/manrope-v15-latin-600.woff2') format('woff2'),
    url('/assets/fonts/google/manrope-v15-latin-600.woff') format('woff'),
    url('/assets/fonts/google/manrope-v15-latin-600.ttf') format('truetype');
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-100.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-100.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-100.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-300.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-300.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-regular.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-500.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-500.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-500.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-600.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-600.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-600.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/assets/fonts/google/montserrat-v29-latin-700.woff2') format('woff2'),
    url('/assets/fonts/google/montserrat-v29-latin-700.woff') format('woff'),
    url('/assets/fonts/google/montserrat-v29-latin-700.ttf') format('truetype');
}

/* Nunito Sans */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/assets/fonts/google/nunito-sans-v15-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/google/nunito-sans-v15-latin-regular.woff') format('woff'),
    url('/assets/fonts/google/nunito-sans-v15-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/assets/fonts/google/nunito-sans-v15-latin-600.woff2') format('woff2'),
    url('/assets/fonts/google/nunito-sans-v15-latin-600.woff') format('woff'),
    url('/assets/fonts/google/nunito-sans-v15-latin-600.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/assets/fonts/google/nunito-sans-v15-latin-700.woff2') format('woff2'),
    url('/assets/fonts/google/nunito-sans-v15-latin-700.woff') format('woff'),
    url('/assets/fonts/google/nunito-sans-v15-latin-700.ttf') format('truetype');
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('/assets/fonts/google/roboto-v47-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/google/roboto-v47-latin-regular.woff') format('woff'),
    url('/assets/fonts/google/roboto-v47-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('/assets/fonts/google/roboto-v47-latin-700.woff2') format('woff2'),
    url('/assets/fonts/google/roboto-v47-latin-700.woff') format('woff'),
    url('/assets/fonts/google/roboto-v47-latin-700.ttf') format('truetype');
}

/* Roboto Slab */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/assets/fonts/google/roboto-slab-v34-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/google/roboto-slab-v34-latin-regular.woff') format('woff'),
    url('/assets/fonts/google/roboto-slab-v34-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/assets/fonts/google/roboto-slab-v34-latin-700.woff2') format('woff2'),
    url('/assets/fonts/google/roboto-slab-v34-latin-700.woff') format('woff'),
    url('/assets/fonts/google/roboto-slab-v34-latin-700.ttf') format('truetype');
}
