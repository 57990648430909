@use '../mixins' as *;
@use '../variables' as *;

.main-menu-button {
  position: relative;
  border: 0px;
  border-radius: 5px;
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  border: 2px solid transparent;
  flex-shrink: 0;
  cursor: pointer;
  background: transparent;
  font-weight: 700;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  box-sizing: border-box;
  transition:
    background-color 0.2s linear,
    color 0.2s linear;
  color: var(--tt-text-default);
  @apply text-md;

  &.small {
    height: 32px;
    i {
      font-size: 1em;
    }
  }
  &.no-limit {
    min-width: initial;
  }
  &.inlined {
    margin-top: 0;
  }
  &:hover,
  &.active {
    background-color: var(--tt-bg-card);
  }

  &.darker {
    &:hover {
      background-color: var(--primary-dark-color);
    }
    &.active {
      background-color: var(--tt-bg-card);
    }
  }

  &.inverted {
    background-color: var(--tt-text-default);
    color: var(--tt-bg-default);
    &:hover {
      background-color: var(--tt-primary, #2e67c7);
      color: var(--tt-on-primary, #f9f9f9);
    }
  }

  &.highlight {
    background-color: var(--tt-primary, #2e67c7);
    color: var(--tt-on-primary, #f9f9f9);
    i,
    mat-icon,
    .text,
    .label {
      color: var(--tt-on-primary, #f9f9f9);
    }
    &.darker {
      &.active {
        i,
        mat-icon,
        .text,
        .label {
          color: var(--on-bg-color, #252d40);
        }
      }
    }

    &.secondary {
      background-color: var(--warning-color, #f6a623);
    }
    &.error {
      background-color: var(--on-error-color, #f44336);
      color: var(--error-color, #fff);
      i,
      mat-icon,
      .text,
      .label {
        color: var(--error-color, #fff);
      }
    }
  }
  &.success {
    background-color: var(--success-color, #1d9650);
    color: var(--on-success-color, #fff);
    i,
    mat-icon,
    .text,
    .label {
      color: var(--on-success-color, #fff);
    }
  }
  &.warning {
    background-color: var(--warning-color, #f6a623);
    color: var(--on-warning-color, #794902);
    i,
    mat-icon,
    .text,
    .label {
      color: var(--on-warning-color, #794902);
    }
  }
  &.error {
    background-color: var(--on-error-color, #f44336);
    color: var(--error-color, #fff);
    i,
    mat-icon,
    .text,
    .label {
      color: var(--error-color, #fff);
    }
  }

  &.highlight,
  &.success,
  &.warning,
  &.inverted,
  &.error {
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      width: 150%;
      padding-top: 150%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      /* background: #000; */
      border-radius: 5000px;
      box-shadow: 0px 0px 0 0 inset rgba(0, 0, 0, 0.03);
      opacity: 0;
      transition:
        box-shadow 0.4s ease-out,
        opacity 0.4s ease-out;
      z-index: 1;
      pointer-events: none;
    }

    i,
    mat-icon,
    .text,
    .label {
      position: relative;
      z-index: 2;
    }
    &:hover {
      &::after {
        box-shadow: 0px 0px 0 150px inset rgba(0, 0, 0, 0.03);
        opacity: 1;
      }
    }
  }

  i {
    font-size: 1.25em;
  }
  &:not(.consistent) .label,
  .text {
    padding: 0 8px;
    @include tiny {
      display: none;
    }
  }
  &.faded {
    opacity: 0.6;
    &.active,
    &:hover {
      opacity: 1;
    }
  }

  &.space-icon {
    i {
      @include mobile {
        padding-left: 5px;
      }
    }
  }

  &.tooltiped {
    .label,
    .text {
      pointer-events: none;
      opacity: 0;
      position: absolute;
      background: var(--on-bg-color, #252d40);
      color: var(--bg-color, #e8ecf1);
      left: -17px;
      top: 50%;
      padding: 10px;
      pointer-events: none;
      border-radius: 5px 5px 5px 5px;
      transform: translateX(-100%) translateY(-50%);
      transition: opacity 0.05s ease-out;
      transition-delay: 0s;
      width: max-content;
      z-index: 10;
      &::before {
        content: '';
        width: 0;
        height: 0;
        right: 1px;
        top: 50%;
        transform: translateX(100%) translateY(-50%);
        border-style: solid;
        border-width: 7.5px 0 7.5px 10px;
        border-color: transparent transparent transparent var(--on-bg-color, #252d40);
        position: absolute;
      }
    }
    &.bottom {
      .label,
      .text {
        left: 50%;
        top: initial;
        bottom: -15px;
        transform: translateX(-50%) translateY(100%);
        &::before {
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent var(--on-bg-color, #252d40) transparent;
          top: 1px;
          left: 50%;
          transform: translateY(-100%) translateX(-50%);
        }
      }
    }
    &:hover {
      &:not([title-tooltip]) {
        .label,
        .text {
          display: flex;
          //transform: translateX(-100%) translateY(-50%);
          opacity: 1;
          transition: opacity 0.15s ease-out;
          transition-delay: 0.75s;
        }
      }
    }
  }

  &.vertical {
    height: initial;
    min-width: 40px;
    flex-direction: column;
  }

  &.scale-up {
    padding: 20px;
    i {
      font-size: 3em;
    }
    .text,
    .label {
      font-size: 1.3em;
    }
  }
  .hint {
    position: absolute;
    bottom: 100%;
    left: 100%;
    border-radius: 5px;
    z-index: 10;
    background-color: var(--tt-text-default);
    pointer-events: initial;
    clip-path: circle(5px at calc(50%) calc(100% + -5px));
    transition: clip-path 0.3s ease-out;
    transform: translateX(calc(-50% - 7px)) translateY(12px);

    &.error {
      background-color: var(--on-error-color);
      color: var(--error-color);
    }
    &.success {
      background-color: var(--success-color);
      color: var(--on-success-color);
    }
    &.info {
      background-color: var(--on-info-color);
      color: var(--info-color);
    }
    &.free {
      background-color: var(--success-color);
      color: var(--on-success-color);
    }
    &.basic {
      background-color: var(--tt-text-default);
      color: var(--tt-bg-default);
    }
    &.pro {
      background-color: var(--warning-color);
      color: var(--on-warning-color);
    }
    &.api {
      background-color: palevioletred;
      color: #fff;
    }
    .label,
    .text {
      background-color: transparent;
      position: relative;
      z-index: 3;
      left: 0px;
      top: 0px;
      opacity: 1;
      transform: none !important;
      &::before {
        display: none;
      }
    }
    &:hover {
      clip-path: circle(100%);
    }
  }
  &.disabled,
  &[disabled='true'],
  &[disabled] {
    // pointer-events: none;
    color: var(--tt-text-disabled);
    cursor: not-allowed;
    i {
      opacity: 0.4;
    }

    &:not(.tooltiped) {
      & > .text,
      & > .label {
        //opacity: 0.4;
      }
    }
    &:hover {
      .hint {
        clip-path: circle(100%);
      }
    }
    &.highlight {
      background: var(--tt-divider);
    }
  }
  &.to-right {
    margin-right: 0px;
    border-radius: 5px 0px 0px 5px;
  }
  &.to-left {
    margin-left: 0px;
    border-radius: 0px 5px 5px 0px;
    &.bordered {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        left: 0px;
        top: 0%;
        // transform: translateY(-50%);
        background-color: currentColor;
        opacity: 0.3;
      }
    }
  }
}

.main-menu-buttons,
.vertical-buttons {
  &.vertical {
    .main-menu-button {
      margin: 4px 0;
      width: 40px;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -7px;
        height: 20px;
        width: 10px;
        border-bottom-right-radius: 20px;
        background: transparent;
        transform: translateY(-100%);
        box-shadow: none;
        pointer-events: none;
        transition: box-shadow 0.2s linear;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        right: -7px;
        height: 20px;
        width: 10px;
        border-top-right-radius: 20px;
        background: transparent;
        transform: translateY(100%);
        box-shadow: none;
        pointer-events: none;
        transition: box-shadow 0.2s linear;
      }
      i,
      mat-icon {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -7px;
          height: 100%;
          width: 7px;
          background-color: none;
          pointer-events: none;
          transition: background-color 0.2s linear;
        }
      }
      &:first-child {
        margin-top: 5px;
      }
      &:hover {
        background-color: var(--tt-bg-card);
        @apply shadow;
      }
      &.active {
        background-color: var(--tt-bg-card);
        color: var(--tt-text-default);
        position: relative;
        @apply shadow;
        &::before {
          box-shadow: 0 10px var(--tt-bg-card);
        }
        &::after {
          box-shadow: 0 -10px var(--tt-bg-card);
        }
        i,
        mat-icon {
          &::after {
            background-color: var(--tt-bg-card);
          }
        }
      }
    }

    &.rounded {
      .main-menu-button {
        border-radius: 50px;
        &.active {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }
  &.vertical-inverted {
    .main-menu-button {
      margin: 4px 0;
      width: 40px;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -7px;
        height: 20px;
        width: 10px;
        border-bottom-left-radius: 20px;
        background: transparent;
        transform: translateY(-100%);
        box-shadow: none;
        pointer-events: none;
        transition: box-shadow 0.2s linear;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -7px;
        height: 20px;
        width: 10px;
        border-top-left-radius: 20px;
        background: transparent;
        transform: translateY(100%);
        box-shadow: none;
        pointer-events: none;
        transition: box-shadow 0.2s linear;
      }
      i,
      mat-icon {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: -7px;
          height: 100%;
          width: 7px;
          background-color: none;
          pointer-events: none;
          transition: background-color 0.2s linear;
        }
      }
      &:first-child {
        margin-top: 5px;
      }
      // &:hover {
      //   background-color: var(--primary-dark-color);
      // }
      &.active {
        background-color: var(--tt-bg-card);
        color: var(--tt-text-default);
        position: relative;
        &::before {
          box-shadow: 0 10px var(--tt-bg-card);
        }
        &::after {
          box-shadow: 0 -10px var(--tt-bg-card);
        }
        i,
        mat-icon {
          &::after {
            background-color: var(--tt-bg-card);
          }
        }
      }
    }
    &.rounded {
      .main-menu-button {
        border-radius: 50px;
        &.active {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
}

.main-menu-button-group {
  margin: 0px;
  padding: 4px;
  border-radius: 30px;
  background: var(--tt-primary);
  .main-menu-button {
    margin: 0px;
    min-height: 32px;
    height: 32px;
    border-radius: 18px;
    color: var(--tt-on-primary);

    &:hover {
      color: var(--on-primary-light-color) !important;
    }
    &.disabled {
    }

    &:not(.dropdown) {
      i {
        @apply ml-1;
      }
      .label {
        @apply mr-1;
      }
    }
  }
  menu-button {
    i {
      @apply ml-0;
    }
  }

  .dropdown {
    margin-left: 3px;
    min-width: 32px;
    width: 32px;
    min-height: 32px;
    height: 32px;
    border-radius: 32px;
  }

  &.success {
    background-color: var(--success-color, #1d9650);
    color: var(--on-success-color, #fff);

    .main-menu-button {
      &::before {
        background-color: var(--success-color, #1d9650) !important;
      }
    }
  }

  &.secondary {
    background-color: var(--warning-color, #f6a623);
    .main-menu-button {
      &::before {
        background-color: var(--warning-color, #f6a623);
      }
    }
  }
  &.warning {
    background-color: var(--warning-color, #f6a623);
    color: var(--on-warning-color, #794902);

    .main-menu-button {
      &::before {
        background-color: var(--warning-color, #f6a623) !important;
      }
    }
  }
  &.error {
    background-color: var(--on-error-color, #f44336);
    color: var(--error-color, #fff);

    .main-menu-button {
      &::before {
        background-color: var(--on-error-color, #f44336) !important;
      }
    }
  }
}
