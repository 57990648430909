.delta-container {
  white-space: pre-wrap;

  table {
    width: 100%;
  }

  // h1 {
  //   @apply text-4xl;
  // }
  // h2 {
  //   @apply text-2xl;
  // }
  // h3 {
  //   @apply text-xl;
  // }

  a {
    color: currentColor;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: currentColor;
    }
  }

  p {
    margin: 0;
    padding: 0;
    // min-width: 100%;
    // min-height: 100%;
    // display: inline;
  }

  img {
    max-width: 100%;
  }
}
