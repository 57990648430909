@use '../mixins' as *;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid #111 !important;
  -webkit-text-fill-color: var(--tt-text-default);

  //creates background shadow for autofill inputs
  // box-shadow: 0 0 0 30px white inset, 0px 0px 10px rgba(0,0,0,0.2) !important;
  box-shadow: 0 0 0 30px var(--tt-bg-card) inset !important;

  transition: background-color 5000s ease-in-out 0s;
}

input:not([type='range']):not([type='checkbox']) {
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  font-size: 1em;
  width: 100%;
  text-overflow: ellipsis;
  box-shadow: 0px 0px;
}

input:not([type='checkbox']) {
  width: 100%;
  //box-shadow: none !important; // for what is the 0px 0px anyway?
}

input[type='range'] {
  + .text-input {
    width: 3.5em;
    margin-left: 1em;
  }
}

@include placeholder {
  color: var(--disabled-color);
  opacity: 0.5;
}

input,
textarea {
  background: transparent;

  /* Placeholder color */
  &::placeholder {
    @apply text-hint;
  }

  &::-moz-placeholder {
    @apply text-hint;
  }

  &::-webkit-input-placeholder {
    @apply text-hint;
  }

  &:-ms-input-placeholder {
    @apply text-hint;
  }

  &:-webkit-autofill {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  &:-webkit-autofill:hover {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  &:-webkit-autofill:focus {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  &:-webkit-autofill:active {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
}
