@use "@angular/cdk/overlay-prebuilt.css";

.cdk-overlay-backdrop {

  &:not(.cdk-overlay-transparent-backdrop){

    background: rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(65px);
  }
  // &:not(.backdrop-transparent) {
  //   backface-visibility: hidden;
  //   backdrop-filter: blur(2px);
  //   will-change: transform;
  // }
  &.darker-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }
  &.backdrop-transparent {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: none;
  }
}
.cdk-overlay-pane {
  max-height: calc(100% - 4em);
  @screen mobile {

    max-height: 100%;
  }


  &.with-shadow {
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.12);
  }
  &.with-shadow-to-bottom {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.12);
  }
  &.with-border-radius{
    border-radius: 5px;
  }
}

.cdk-overlay-connected-position-bounding-box {
  &.on-top {
    z-index: 1001;
  }
  &.below {
    z-index: 999;
  }
}


.cdk-global-scrollblock{
  @apply overflow-hidden #{!important}
}