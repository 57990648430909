@use 'sass:math';
@use 'sass:color';
@use 'sass:list';

@use 'variables' as *;
@use 'background' as *;

@mixin defaultStyling {
  // font-family: $main-font;
  // font-weight: 400;
  // font-size: 13px;
  color: var(--tt-text-default);
  text-shadow: none;
}
@mixin main-page {
  :host {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
  }
}
// Fill up the parent container fully
@mixin fullpage($direction: row, $overflow: initial) {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  flex-direction: $direction;
  overflow: $overflow;
}

@mixin fullpage-absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin transition($type: all, $duration: 0.23s, $ease: ease-out) {
  transition: $type $duration $ease;
}

@mixin shadowmagic {
  transition:
    box-shadow 0.2s ease,
    background-color 0.2s ease;

  &:hover {
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    // box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -1px rgba(0, 0, 0, 0.12);
  }
}

@mixin lightBorder {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

@mixin roundBorder($radius: 20px) {
  border-radius: $radius;
}

@mixin align-items($items, $content) {
  display: flex;
  align-items: $items;
  justify-content: $content;
}

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow:
    $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size $shadow-color;
}

@mixin thumb(
  $thumb-color,
  $adjustment: 0,
  $thumb-shadow-size: 0px,
  $thumb-shadow-blur: 0px,
  $thumb-shadow-color: rgba(0, 0, 0, 1),
  $thumb-height: 14px,
  $thumb-width: 14px,
  $thumb-border-width: 0px,
  $thumb-radius: 50%
) {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-color;
  border-radius: $thumb-radius;
  cursor: ew-resize;
  height: $thumb-height + $adjustment;
  width: $thumb-width + $adjustment;
}

@mixin rangeInputColors($thumb-color, $track-color, $contrast: 5%, $track-shadow-size: 0px, $track-shadow-blur: 0) {
  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: $track-color;
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: $track-color;
    }
  }

  &::-webkit-slider-thumb {
    @include thumb($thumb-color);
  }

  &::-moz-range-thumb {
    @include thumb($thumb-color, -4);
  }

  &::-webkit-slider-runnable-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-color);
    background: $track-color;
    border-color: $track-color;
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-color);
    background: $track-color;
    border-color: $track-color;
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-color);
    background: $track-color;
    border-color: $track-color;
  }
}

@mixin placeholder {
  ::placeholder {
    @content;
  }

  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }

  // .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  //   @content;
  // }
}

@mixin tiny {
  @media (max-width: #{$tiny}) {
    @content;
  }
}

@mixin low {
  @media (max-width: #{$low - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin notMobile {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop - 1}) {
    @content;
  }
}

@mixin notTablet {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin ios5Height {
  @media screen and (device-aspect-ratio: 40/71) {
    height: 100%;
  }
}

@mixin maxWidth($width) {
  @media (max-width: #{$width - 1}px) {
    @content;
  }
}

@mixin minWidth($width) {
  @media (min-width: #{$width}px) {
    @content;
  }
}

@mixin maxHeight($height) {
  @media (max-height: #{$height - 1}px) {
    @content;
  }
}

@mixin minHeight($height) {
  @media (min-height: #{$height}px) {
    @content;
  }
}

@mixin hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin bubbleCheckbox {
  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
    float: left;
    margin-right: 0.75em;
    background: transparent;
    border: none;
  }

  input[type='checkbox']:checked,
  input[type='checkbox']:not(:checked),
  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    background: transparent;
    position: relative;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  input[type='checkbox'] + label,
  input[type='radio'] + label {
    cursor: pointer;
  }

  input[type='checkbox']:checked + label::before,
  input[type='checkbox']:not(:checked) + label::before,
  input[type='radio']:checked + label::before,
  input[type='radio']:not(:checked) + label::before {
    content: ' ';
    display: inline-block;
    width: 1em;
    min-width: 1em;
    height: 1em;
    min-height: 1em;
    position: relative;
    background: #000;
    margin-right: 5px;
    box-shadow: inset 0 0 0 2px #000;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.05 linear;
    border-radius: 50%;
  }

  input[type='radio']:checked + label::before,
  input[type='radio']:not(:checked) + label::before {
    border-radius: 30px;
  }

  input[type='checkbox']:hover + label::before,
  input[type='radio']:hover + label::before {
    background: rgba(255, 255, 255, 0.6);
    //box-shadow: inset 0 0 0 2px white;
  }

  input[type='checkbox']:checked + label::before,
  input[type='radio']:checked + label::before {
    background: var(--tt-text-default);
    // box-shadow: inset 0 0 0 2px white;
  }

  input[type='checkbox'] {
    + label {
      display: flex;
      align-items: center;
      position: relative;

      .name {
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        transform-origin: left center;
        //transform: scale(0.85);
        transition: all 0.05s linear;
        opacity: 0.4;
        padding: 0 0.8em;
      }

      img {
        filter: grayscale(1);
        transform: scale(0.8);
      }

      &::before {
        top: 0;
        display: none;
      }

      &:hover {
        .name {
          opacity: 1;
        }
      }
    }

    &:checked {
      + label {
        &::after {
          position: absolute;
          content: ' ';
          width: 0.8em;
          height: 0.3em;
          border-left: 2px solid #000;
          border-bottom: 2px solid #000;
          transform: rotate(-45deg);
          top: 0.25em;
          left: 0.1em;
          display: none;
        }

        &::before {
          background-color: var(--tt-text-default);
          border-color: var(--tt-text-default);
          top: 0;
          box-shadow: 0 0 0;
        }

        .name {
          transform: scale(1);
          opacity: 1;
        }

        img {
          filter: grayscale(0);
          transform: scale(1);
        }
      }
    }
  }
}

@mixin tinyTooltip {
  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    text-transform: initial;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: auto;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      /* At the top of the tooltip */
      left: 50%;
      margin-left: -2px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}

@mixin pulsate {
  .pulsate {
    animation: pulsate 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
  @keyframes pulsate {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
}

@mixin customScrollbar {
  *::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  *::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    // outline: 1px solid slategrey;
  }

  @include mobile {
    *::-webkit-scrollbar {
      width: initial;
      height: initial;
    }

    *::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: initial;
    }

    *::-webkit-scrollbar-thumb {
      background-color: initial;
      // outline: 1px solid slategrey;
    }

    * {
      // FireFox
      scrollbar-width: auto;
    }
  }

  * {
    // FireFox
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  * {
    scrollbar-face-color: transparent;
    scrollbar-track-color: rgba(0, 0, 0, 0.2);
  }
}

@mixin customScrollbarLight {
  *::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  *::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    // outline: 1px solid slategrey;
  }

  @include mobile {
    *::-webkit-scrollbar {
      width: initial;
      height: initial;
    }

    *::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: initial;
    }

    *::-webkit-scrollbar-thumb {
      background-color: initial;
      // outline: 1px solid slategrey;
    }

    * {
      // FireFox
      scrollbar-width: auto;
    }
  }

  * {
    // FireFox
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  }

  * {
    scrollbar-face-color: transparent;
    scrollbar-track-color: rgba(255, 255, 255, 0.2);
  }
}

@mixin bookloader($bookcolor: white) {
  // Bookloader taken from https://codepen.io/podenemus/pen/eJqwBL
  // Use like
  // <div class="book">
  //   <figure class="page"></figure>
  // </div>
  .book {
    border: 4px solid $bookcolor;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
  }

  .page {
    display: block;
    width: 30px;
    height: 45px;
    border: 4px solid $bookcolor;
    border-left: 1px solid $bookcolor;
    margin: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    overflow: hidden;
    background: transparent;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }

  .book .page:nth-child(1) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.6s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.6s infinite;
  }

  .book .page:nth-child(2) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.45s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.45s infinite;
  }

  .book .page:nth-child(3) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.2s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 1.2s infinite;
  }
  /* Page turn */
  @-webkit-keyframes pageTurn {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }

    20% {
      background: transparent;
    }

    40% {
      background: transparent;
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
    }

    100% {
      background: transparent;
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
    }
  }
  @keyframes pageTurn {
    0% {
      transform: rotateY(0deg);
    }

    20% {
      background: transparent;
    }

    40% {
      background: transparent;
      transform: rotateY(-180deg);
    }

    100% {
      background: transparent;
      transform: rotateY(-180deg);
    }
  }
}

@function longshadow($color_a, $color_b, $stepnum, $opacity: 1) {
  $gradient_steps: null;
  @for $i from 1 through $stepnum {
    $weight: math.div($i - 1, $stepnum) * 100;
    $colour_mix: color.mix($color_b, rgba($color_a, $opacity), $weight);
    $seperator: null;
    @if ($i != $stepnum) {
      $seperator: #{','};
    }
    $gradient_steps: list.append(#{$gradient_steps}, #{$i}px #{$i}px $colour_mix $seperator);
  }
  @return $gradient_steps;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin linearGradient($colors) {
  // background-image: linear-gradient(-45deg, nth($colors, 1) 12%, nth($colors, 2) 37%, nth($colors, 2) 62%, nth($colors, 1) 87%);
  // background-size:  200% auto;
  background-image: linear-gradient(-135deg, list.nth($colors, 1) 0%, list.nth($colors, 2) 100%);
}

@mixin linearGradientBorder($colors) {
  border-image-source: linear-gradient(-45deg, list.nth($colors, 1) 0%, list.nth($colors, 2) 100%);
}

@mixin nakedButton {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
