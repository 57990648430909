@use 'mixins' as *;

$ng-select-highlight: var(--border-active-color) !default;
$ng-select-primary-text: currentColor; // var(--on-form-bg-color) !default;
$ng-select-disabled-text: var(--on-disabled-color) !default;
$ng-select-disabled-bg: var(--disabled-color) !default;
$ng-select-border: var(--tt-border) !default;
$ng-select-bg: var(--tt-bg-dialog) !default;
$ng-select-selected: rgba(24, 0, 0, 0.05);
$ng-select-marked: var(--border-active-color) !default;

.ng-select {
  position: relative;
  &.ng-select-opened {
    &.ng-select-clearable .ng-select-container.ng-has-value:hover {
      .ng-clear-wrapper {
        opacity: 0;
        pointer-events: none;
      }
      .ng-arrow-wrapper {
        opacity: 1;
      }
    }

    > .ng-select-container {
      background: $ng-select-bg;
      border-color: $ng-select-highlight;
      box-shadow: 0 0 5px 2px $ng-select-selected;
      .ng-arrow-wrapper {
        transform: rotate(180deg);
        opacity: 1;
        @include rtl {
          transform: rotate(-180deg);
        }
      }
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      border-color: $ng-select-highlight;
    }
  }
  &.ng-select-opened,
  &.ng-select-focused {
    &:not(.ng-select-disabled) {
      .ng-select-container {
        @apply bg-card border-primary dark:bg-card dark:border-primary;
      }
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-bg;
      &:hover {
        border: 1px solid $ng-select-border;
        cursor: not-allowed;
      }
      .ng-value-container {
        .ng-value {
          color: $ng-select-disabled-text;
        }
      }
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }

  &.ng-select-clearable .ng-select-container.ng-has-value:hover {
    .ng-clear-wrapper {
      opacity: 1;
    }
    .ng-arrow-wrapper {
      opacity: 1;
      // pointer-events: none;
    }
  }

  .ng-select-container {
    // background-color: $ng-select-bg;
    border-radius: 3px;
    // border: 1px solid $ng-select-border;
    min-height: 30px;
    align-items: center;
    transition: all 0.3s;
    box-sizing: border-box;
    padding-right: 11px;
    padding-left: 11px;
    border-width: 1px;

    @apply border-gray-300 bg-white shadow-none dark:border-gray-500 dark:bg-black dark:bg-opacity-5;

    .ng-input {
      line-height: 24px;
      input {
        color: $ng-select-primary-text;
        padding: 0px;
      }
    }
    .ng-value-container {
      align-items: center;
      .ng-value {
        //font-size: 14px;
        color: $ng-select-primary-text;
      }
      .ng-placeholder {
        //font-size: 14px;
        color: $ng-select-primary-text;
        opacity: 0.7;
        //color: var(--disabled-color); //lighten($ng-select-primary-text, 60);
      }
      .ng-value-block {
        display: inline-block;
        &.active {
          background-color: var(--tt-primary);
        }
      }
    }
  }
  &.ng-select-single {
    &.ng-select-opened .ng-select-container .ng-value {
      opacity: 0.4;
    }

    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 2px;
          left: 0;
          padding-left: 10px;
          padding-right: 50px;
          @include rtl {
            padding-right: 10px;
            padding-left: 50px;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-bg;
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      min-height: 32px;
      padding-left: 5px;

      @include rtl {
        padding-right: 5px;
        padding-left: 11px;
        .ng-value-container .ng-placeholder {
          margin-left: 0;
          margin-right: 6px;
        }
      }
      .ng-value-container {
        padding-bottom: 3px;
        .ng-value {
          margin-top: 3px;
          margin-right: 4px;
          /* font-size: 0.9em; */
          background-color: var(--tt-text-default);
          border: 1px solid #e8e8e8;
          border-radius: 3px;
          /* height: 24px; */
          line-height: 22px;
          color: var(--tt-bg-default);
          @include rtl {
            margin-right: 0;
            margin-left: 4px;
          }
          &.ng-value-disabled {
            background-color: $ng-select-disabled-bg;
            color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 0 5px;
            color: var(--tt-bg-default);
          }
          .ng-value-icon {
            display: inline-block;
            padding: 0 5px;
            color: var(--tt-bg-default);
          }
        }
        .ng-placeholder {
          top: 50%;
          transform: translateY(-50%);
          margin-left: 7px;
          left: 0;
        }
        .ng-input {
          height: 24px;
          margin-top: 3px;
        }
      }
    }
  }
  .ng-clear-wrapper {
    opacity: 0;
    color: var(--tt-on-accent); // darken($ng-select-border, 20);
    background: rgba(var(--tt-accent-rgb), 0.5);
    width: 17px;
    height: 17px;
    text-align: center;
    border-radius: 3px;
    vertical-align: middle;
    transition: color 0.3s ease;
    position: absolute;
    right: 5px;
    z-index: 3;
    @include rtl {
      left: 5px;
      right: auto;
    }

    &:hover {
      background: rgba(var(--tt-accent-rgb), 1);
    }
    .ng-clear {
      color: var(--tt-on-accent);
      font-size: 9px;
      position: absolute;
      width: 100%;
      left: 0;
      line-height: 12px;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }
  .ng-arrow-wrapper {
    width: 16px;
    height: 26px;
    transition: transform 0.3s;
    justify-content: center;
    align-items: center;
    display: flex;
    .ng-arrow {
      border-style: solid;
      border-width: 0.1rem 0.1rem 0 0;
      height: 8px !important;
      width: 8px !important;
      top: -2px;
      transform: rotate(135deg);
      color: $ng-select-disabled-text;
    }
  }

  &.showOnActive {
    .ng-select-container {
      background: transparent;
      border-color: transparent;
    }
    &:hover {
      .ng-select-container {
        background-color: $ng-select-bg;
        border-color: $ng-select-border;
      }
    }
    &.ng-select-focused {
      .ng-select-container {
        background-color: $ng-select-bg;
        border-color: $ng-select-border;
        @apply bg-card border-primary dark:bg-card dark:border-primary;
      }
    }
  }

  /*

  NEW NG SELECT STYLING

*/

  // &.form-select {
  //   .ng-select-container {
  //     line-height: 24px ;
  //     padding: 10px 16px ;
  //     border-radius: 6px ;
  //     background: #f3f3f4 ;
  //     border: 0px;
  //     transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease;
  //     .ng-value-container {
  //       .ng-input {
  //         line-height: 24px;
  //         padding: 10px 16px;
  //         top: 0;
  //       }
  //     }
  //   }
  //   &.ng-select-focused,
  //   &:hover {
  //     .ng-select-container {
  //       background-color: #fff;
  //       border-color: rgba(46, 103, 199, 0.4);
  //       box-shadow: 0 0 0 4px rgba(46, 103, 199, 0.1);
  //       .ng-arrow-wrapper {
  //         .ng-arrow {
  //           opacity: 1;
  //           color: var(--tt-text-default);
  //         }
  //       }
  //     }
  //   }
  // }

  &.next {
    .ng-select-container {
      line-height: 24px !important;
      padding: 10px 16px !important;
      border-radius: 6px !important;
      background: var(--tt-bg-card) !important;
      border: 1px solid var(--tt-border);
      transition:
        background-color 100ms ease,
        border-color 200ms ease,
        color 200ms ease,
        box-shadow 200ms ease;
      .ng-value-container {
        .ng-input {
          top: 10px;
          padding-left: 15px;
        }
      }
    }
    &.ng-select-disabled {
      //  border: 1px solid var(--form-bg-color-new);
      // &:hover {
      //   .ng-select-container {
      //     border: 1px solid var(--form-bg-color-new);
      //   }
      // }
    }
    &:hover {
      &:not(.ng-select-disabled) {
        .ng-select-container {
          background-color: var(--tt-bg-card) !important;
          border-color: var(--tt-border) !important;
          box-shadow: 0 0 0 4px rgb(var(--tt-primary-rgb) / 0.5) !important;
        }
      }
    }

    &.ng-select-opened,
    &.ng-select-focused {
      &:not(.ng-select-disabled) {
        .ng-select-container {
          background-color: var(--tt-bg-card) !important;
          border-color: var(--form-border-color) !important;

          @apply bg-card border-primary dark:bg-card dark:border-primary;
        }
      }
    }
  }

  &.clean {
    .ng-select-container {
      background-color: transparent !important;
      border: 0px solid var(--border-color) !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      .ng-value-container {
        .ng-input {
          padding-left: 1px;
          padding-right: 16px;
        }
      }
    }
    &.ng-select-opened {
      .ng-select-container {
        box-shadow: none;
      }
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  left: 0;
  &.ng-select-bottom {
    top: 100%;
    border-top-color: var(--border-color); // lighten($ng-select-border, 10);
  }
  &.ng-select-top {
    bottom: 100%;
    border-bottom-color: var(--border-color); // lighten($ng-select-border, 10);
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 4px 12px;
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
        color: var(--tt-text-default);
      }
      &.ng-option-marked {
        //background-color: $ng-select-marked;
      }
      &.ng-option-selected {
        background-color: var(--popup-form-color);
        color: var(--tt-bg-default);
        font-weight: 700;
      }
      &.ng-option-selected.ng-option-marked {
        //background-color: $ng-select-marked;
      }
    }
    .ng-option {
      background-color: $ng-select-bg;
      color: $ng-select-primary-text;
      padding: 5px 12px;
      // transition: background 0.3s ease;
      opacity: 0.5;
      svg {
        path {
          stroke: $ng-select-primary-text;
          fill: $ng-select-primary-text;
        }
      }
      &:first-child {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }
      &:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &.ng-option-selected {
        // background-color: var(--tt-text-default);
        // color: var(--tt-bg-default);
        font-weight: 700;
        opacity: 1;
      }
      &.ng-option-selected.ng-option-marked {
        // color: $ng-select-primary-text;
        // background-color: $ng-select-marked;
      }
      &.ng-option-marked {
        // background-color: $ng-select-marked;
        // color: $ng-select-primary-text;
        opacity: 1;
      }
      &.ng-option-disabled {
        color: $ng-select-disabled-text;
        cursor: not-allowed;
      }
      &.ng-option-child {
        padding-left: 20px;
        @include rtl {
          padding-right: 20px;
          padding-left: 0;
        }
      }
      .ng-option-label {
        // font-size: 14px;
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }
  &.fullcolor {
    .ng-dropdown-panel-items {
      .ng-option {
        opacity: 1;
        // &.ng-option-marked {
        //   span {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  &.auto-size {
    width: auto !important;
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}

// .modal-overlay-open {
//   .ng-dropdown-panel {
//     background-color: var(--popup-form-bg-color);
//     .ng-dropdown-panel-items {
//       .ng-option {
//         background-color: transparent;
//         color: var(--on-popup-color);
//         &.ng-option-selected {
//         }
//         &.ng-option-marked {
//         }
//         &.ng-option-selected.ng-option-marked {
//         }
//       }
//     }
//   }
// }
