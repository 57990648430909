@use 'mixins' as *;
@use 'variables' as *;

html {
  touch-action: manipulation;
  // line-height: 1.15;
}

body,
html {
  width: 100%;
  max-width: 100vw;
  //min-height: 100%;
  // height: 100%;
  @include defaultStyling;
  //Setting display:flex causes a glitch in the views
  display: flex;
  padding: 0;
  margin: 0;
  background-color: transparent;
  &.native-scrolling {
    background-color: var(--tt-bg-default);
    height: initial;
  }
}

body {
  // Add to "fix" rubberbanding on body on iOs
  // position: fixed;
  background: transparent;
  display: flex;
  flex: 1;
  font-size: 0.875rem;
  overflow-x: hidden;
  &.native-scrolling {
    position: static;
  }
}

*:not(button):focus {
  &:not(mat-drawer) {
    outline: none;
    background: none;
  }
}

a {
  text-decoration: none;
  color: var(--tt-text-default);
}

a:visited {
  color: var(--tt-text-default);

  &.light {
    color: #fff;
  }
}

.big {
  font-size: 1.5em;
}

small {
  font-size: 0.85em;
}

.mobile-view {
  .hide-on-mobile,
  .hide-mobile {
    display: none !important;
  }
}

.hide {
  display: none !important;
}
.hide-desktop {
  display: none;
  @include mobile {
    display: flex;
  }
}

.auto {
  flex: 1 0 auto;
}

mark {
  background-color: transparent;
  font-weight: 600;
}
@include mobile {
  .hide-on-mobile,
  .hide-mobile {
    display: none !important;
  }
}

#us_report_button {
  right: auto;
  left: 30px;
}

.clickable {
  cursor: pointer;
}

.default {
  cursor: default;
}

.cursor-hor-resize {
  cursor: ew-resize;
}

.forbidden {
  cursor: not-allowed;
}

.capitalize {
  text-transform: capitalize;
}

.hover {
  &:hover {
    background-color: $lightgrey;
  }
}

.white-bg {
  background-color: var(--tt-bg-card);
}

.ng-dropdown-panel {
  // width: auto !important;
}

pre {
  padding: 10px 16px;
  border-radius: 6px;
  // background: #f3f3f4;
  background: var(--form-bg-color-new);
  transition:
    background-color 200ms ease,
    border-color 200ms ease,
    color 200ms ease,
    box-shadow 200ms ease;
  border: 1px solid transparent;
  word-break: break-word;
  &:focus,
  &:hover {
    background-color: var(--form-bg-hover-color) !important;
    border-color: var(--form-border-color) !important;
    box-shadow: 0 0 0 4px var(--form-shadow-color) !important;
  }
}
