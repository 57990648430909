@use 'styles/mixins' as *;
@use 'styles/variables' as *;

span.ripple-effect {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


.button {
  outline: none;
  border: 0px solid $white;
  background-color: $black;
  color: $white;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  // letter-spacing: 1px;
  border-radius: 5px;
  transition: all 0.15s ease;
  flex-shrink: 0;
  position: relative;
  @include mobile {
    padding: 0.5em;
  }

  &.blank {
    border: 0px solid $black;
    background-color: #fff;
    color: $black;
    &:visited {
      color: $black;
    }
    &:hover {
      color: $white;
    }
  }

  &.small {
    font-size: 0.8em;
    padding: 0.5em;
  }

  &:hover:not([disabled]) {
    &:not(.transparent) {
      background-color: $green;
      i {
        //color: rgb(245, 244, 244);
        color: currentColor;
      }
    }
  }

  &:disabled,
  &.clean:disabled {
    cursor: not-allowed;
    //background-color: rgba(0, 0, 0, 0.5) !important;
    //color: rgba(255, 255, 255, 0.1) !important;
    opacity: $fade-out;
    &.blank,
    &.blank:hover,
    &.blank:hover i {
      background-color: transparent !important;
      color: var(--disabled-color) !important;
    }
    &.green,
    &.green:hover,
    &.green:hover i {
      background-color: $green !important;
      color: $white !important;
    }
  }

  // &:not([disabled]) {
  //   @include shadowmagic();
  // }

  &.social {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin: 8px;
    height: 40px;
    min-width: 40px;
    border: 2px solid transparent;
    text-transform: initial;
    transition: all 0.3s ease;
    i {
      font-size: 1.5em;
      margin-left: 5px;
    }
    .label,
    .text {
      padding: 0 8px;
    }
    &.google {
      &:hover {
        background-color: #db4437;
      }
    }
    &.dpa {
      &:hover {
        background-color: #3a67e5;
      }
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }

  &.tooltiped {
    i {
      margin: 0;
    }
    .label,
    .text {
      pointer-events: none;
      opacity: 0;
      position: absolute;
      background: var(--tt-text-default);
      color: var(--tt-bg-default);
      left: -17px;
      top: 50%;
      padding: 10px;
      pointer-events: none;
      border-radius: 5px 5px 5px 5px;
      transform: translateX(-100%) translateY(-50%);
      transition: opacity 0.05s ease-out;
      transition-delay: 0s;
      width: max-content;
      z-index: 3;
      &::before {
        content: "";
        width: 0;
        height: 0;
        right: 1px;
        top: 50%;
        transform: translateX(100%) translateY(-50%);
        border-style: solid;
        border-width: 7.5px 0 7.5px 10px;
        border-color: transparent transparent transparent var(--tt-text-default);
        position: absolute;
      }
    }
    &.bottom {
      .label,
      .text {
        left: 50%;
        top: initial;
        bottom: -15px;
        transform: translateX(-50%) translateY(100%);
        &::before {
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent var(--tt-text-default) transparent;
          top: 1px;
          left: 50%;
          transform: translateY(-100%) translateX(-50%);
        }
      }
    }
    &:hover {
      .label,
      .text {
        display: flex;
        //transform: translateX(-100%) translateY(-50%);
        opacity: 1;
        transition: opacity 0.15s ease-out;
        transition-delay: 0.75s;
      }
    }
  }
}
