/* You can add global styles to this file, and also import other style files */
// @use 'tailwindcss/base';
// @use 'tailwindcss/components';
// @use 'tailwindcss/utilities';

@use 'styles/mixins' as *;
@use 'styles/variables' as *;
@use 'styles/tailwind';

@use 'styles/typo';
@use 'styles/notifications';
@use 'styles/forms/ng-select/ng-select';
@use 'styles/forms';
@use 'styles/overlay';
@use 'styles/delta-content';
@use 'styles/customScrollBar';
@use 'styles/main';

.center {
  @apply items-center justify-center;
}

.hide-mobile {
  @apply hidden;
  @screen sm {
    @apply inline-block;
  }
}

#cc-main{
  @apply hidden;
}