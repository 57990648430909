@use 'mixins' as *;
@use 'variables' as *;

body {
  .simple-notification-wrapper {
    // &::before{
    //   content: '';
    //   position: absolute;
    //   width: calc(100%  + 40px);
    //   height: calc(100%  + 40px);
    //   left: -20px;
    //   top: -20px;
    //   backdrop-filter: blur(2px);
    //   @include mobile{
    //     display: none;
    //   }
    // }
    z-index: 1001;
    // min-width: 300px;
    // max-width: 500px;
    // width: auto;
    // &.bottom{
    //   width: 100%;
    //   bottom: 0;
    // }
    // &.right{
    //   right: 0;
    // }
    width: 32%;
    max-width: calc(600px - 20px);
    min-width: calc(450px - 20px);
    &.right {
      right: 0;
    }
    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 5px;
    }

    @include mobile {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      .simple-notification {
        margin-bottom: 2px;
        border-radius: 0px;
        .sn-progress-loader {
          span {
            border-radius: 0;
          }
        }
      }
      &.bottom {
        bottom: 0;
      }
      &.right {
        right: 0;
      }
      &.top {
        top: 5px;
      }
    }
  }
  .simple-notification {
    border-radius: 5px;
    min-height: 60px;
    padding: 10px 15px;
    margin-bottom: 5px;

    .simple-notification.has-icon .sn-content,
    .simple-notification.has-icon .sn-html,
    .simple-notification.has-icon .sn-title {
      padding: 0 30px 0 0;
    }

    .sn-title {
      // line-height: 20px;
      // font-size: clamp(13px, 2vw + 1rem, 16px);
      // margin-bottom: 5px;
      // margin-top: 5px;
      line-height: 20px;
      font-size: 16px;
      font-weight: 700;
    }
    .sn-content {
      font-size: 13px;
    }
    .sn-progress-loader {
      opacity: 0.5;
      span {
        border-radius: 5px 5px 0 0;
      }
    }
    .icon {
      width: 50px;
      height: 50px;
      padding: 10px;
    }
    // margin-bottom: 0px;
    &.bare {
      background: var(--info-color);
      .sn-progress-loader {
        span {
          background-color: var(--info-color);
        }
      }
    }
  }
}
