// @use url('https://fonts.googleapis.com/css?family=Work+Sans:200,300,500,700');
//

@use '../../../src/assets/fonts/23icons/css/23icons' as icons23;
@use '../../../src/assets/fonts/system-fonts.css';

// @use url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap');

// @use url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
// @use url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap');

// Hack to be icons centered within its container no width 1em;
// [class^="icon-"]:before, [class*=" icon-"]:before{
//     width: auto;
// }

// @use 'assets/fonts/worksans/css/worksans.css';

// modern-screenshot does not resolve :before or :after fonts
i {
  font-family: '23icons';
}

.r45 {
  transform: rotate(45deg);
}
