// @media screen and (min-width: 768px) {

:root {
  scrollbar-color: var(--scrollbar-color, #808080) transparent !important;
  scrollbar-width: thin !important;
}

body:not(.touch-device) {
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color, #808080);
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  // Chrome track
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Chrome handle
  &::-webkit-scrollbar-thumb {
    // background: #cdcdcd;
    border-radius: 5px;
  }

  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color,#808080) transparent; 

  // Firefox
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color, #808080) transparent;
  }

  // Chrome width/height
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  // Chrome track
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  // Chrome handle
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
    box-shadow: inset 0 0 0 20px rgb(0 0 0 / 24%);
  }

  // Chrome width/height
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  // Chrome track
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Chrome handle
  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
    box-shadow: inset 0 0 0 20px rgb(0 0 0 / 24%);
    background: transparent;
  }

  //
  // page {
  //   * {
  //     scrollbar-color: var(--scrollbar-color) transparent;
  //   }
  //   ::-webkit-scrollbar-thumb {
  //     background: var(--scrollbar-color);
  //   }
  // }

  // .notification-window,
  // .cdk-overlay-pane,
  // .ng-dropdown-panel.blank,
  // layout-container {
  //   ::-webkit-scrollbar-thumb {
  //     background: #b4b3b4;
  //   }
  // }
}
//   ::-webkit-scrollbar:hover {
//     box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
//   }

//   ::-webkit-scrollbar-thumb {
//     background: linear-gradient(to bottom,  #888 0%, transparent 30%, transparent 70%, #888 100%);
//     border-top: 1px solid #888;
//   }
// }
