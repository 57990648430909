// @use 'styles/mixins' as *;
@use 'styles/variables' as *;

.ng-select {
  &.inVisual {
    min-width: 150px;
    &.nested {
      padding-bottom: 0px !important;
      padding-top: 0em !important;
      max-width: calc(100vw - 20px);
    }

    .button {
      &.blank {
        &.select-all {
          background: transparent;
          &:hover {
            background: transparent;
          }
        }
      }
    }

    .ng-dropdown-header {
      min-height: initial;
      line-height: initial;
      padding: 10px;
      padding-top: 0px;
      border-bottom: 0px;
    }

    .ng-dropdown-panel {
      position: static;
      //@include customScrollbar();

      .scrollable-content {
        position: static;

        .total-padding {
          display: none;
        }
      }
      .ng-dropdown-panel-items .ng-option {
        padding: 0;
        background: transparent;
        .body {
          padding: 5px 16px;
          min-height: 3em;
          .label{
            max-width: 100%;
            white-space: initial;
          }
        }
        &:first-child,
        &:last-child {
          border-radius: 0;
        }

        // COLORE DEPENDING HIGHLIGHT
        &.ng-option-selected {
          background: transparent !important;
          .body {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background-color: currentColor;
              opacity: 0.2;
              pointer-events: none;
            }
          }
        }
        &.ng-option-marked {
          opacity: 0.5;
          &.ng-option-selected,
          &:hover {
            opacity: 1;
          }
        }
      }

      &.dropdown-open {
        .ng-dropdown-header {

          @apply bg-white;
        }

        .ng-dropdown-panel-items {
          @apply bg-white;
          max-height: 45vh;
        }

        .ng-option {
          padding: 0.5em;

          .body {
            position: relative;
            z-index: 2;
            max-width: 236px;
          }

          &:hover {
            position: relative;

            &::before {
              content: '';
              pointer-events: none;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 1;
            }
          }
          .unique {
            border-radius: 100px;
            width: 18px;
            height: 18px;
            margin-right: 2px;
            &:hover {
              background: #fff;
              color: #111;
            }
          }

          &.ng-option-selected {
            background: rgba(0, 0, 0, 0.2) !important;
           @apply text-white #{!important};
            &:hover {
              &::before {
                background: rgba(0, 0, 0, 0.2);
              }
            }

            .unique {
              &:hover {
                background: #fff;
                color: #111;
              }
            }
          }
        }
      }
    }

    .ng-select-container {
      opacity: 0.7;
      background: transparent !important;
      border: 0px !important;
      box-shadow: none;

      .ng-arrow-wrapper {
        display: none;
      }

      .ng-value-container {
        .ng-placeholder {
          color: currentColor !important;
          display: flex;
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          color: currentColor !important;
        }
      }

      &::after {
        border-color: currentColor !important;
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        opacity: 1;
        .ng-value-container {
          .ng-placeholder {
            color: currentColor !important;
          }
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            color: currentColor !important;
          }
        }

        &::after {
          border-color: currentColor !important;
        }
      }
    }
  }
}

